import 'regenerator-runtime/runtime';
import { loadjQuery } from './utils';
import debounce from 'lodash.debounce';
import Mustache from 'mustache';
import './brand-logo-v2.scss';

let $;

(async function({
    debug = false,
    graphQLToken = '',
    productViewSelector = '.productView',
    getProductIdFunc = $productView => Number($productView.find('input[name=product_id]').filter((i, el) => $(el).closest('[data-also-bought]').length === 0).val()),
    brandImageWidth = 100,
    brandImageHeight = 50,
    brandImageTemplate = `
        <img class="_brandImg" src="<%img%>" alt="<%alt%>">
    `,
    renderBrandImageFunc = ($productView, html) => {
        $productView.find('.productView-brand a').prepend(html);
    },
} = window.PAPATHEMES_BRANDLOGOV2_SETTINGS || {}) {
    const fetchProductBrands = async (productIds) => {
        const resp = await $.ajax({
            url: '/graphql',
            method: 'POST',
            data: JSON.stringify({
                query: `
                    query {
                        site {
                            products(entityIds: ${JSON.stringify(productIds)}, first: ${productIds.length}) {
                                edges {
                                    node {
                                        entityId
                                        brand {
                                            name
                                            defaultImage {
                                                url(width: ${brandImageWidth}, height: ${brandImageHeight})
                                            }
                                            path
                                        }
                                    }
                                }
                            }
        
                        }
                    }
                `,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${graphQLToken}`,
            },
            xhrFields: {
                withCredentials: true,
            },
        });

        const items = resp.data.site.products.edges.map(({node}) => ({
            productId: node.entityId,
            brandName: node.brand.name,
            brandImageUrl: node.brand.defaultImage ? node.brand.defaultImage.url : '',
            brandUrl: node.brand.path,
        }));

        debug && console.log('fetchProductBrands = ', items);

        return items;
    }

    const onDOMChanged = async () => {
        const views = [];

        $(productViewSelector)
            .not('._papathemesBrandLogoV2Loaded')
            .addClass('_papathemesBrandLogoV2Loaded')
            .each((i, el) => {
                const $productView = $(el);
                const productId = getProductIdFunc($productView);

                if (!productId) {
                    return;
                }

                views.push({
                    $productView,
                    productId,
                });
            })

        if (views.length === 0) {
            return;
        }

        const productIds = views.reduce((_productIds, { productId }) => _productIds.includes(productId) ? _productIds : [..._productIds, productId], []);
        const brands = await fetchProductBrands(productIds);

        views.forEach(({ $productView, productId }) => {
            const brand = brands.filter(brand => brand.productId === productId)[0];

            if (!brand || !brand.brandImageUrl) {
                return;
            }

            const html = Mustache.render(brandImageTemplate, {
                img: brand.brandImageUrl,
                alt: brand.brandName,
            }, null, ['<%', '%>']);

            renderBrandImageFunc($productView, html);
        });
    }

    const onReady = () => {
        var mo = new MutationObserver(debounce(onDOMChanged, 300));
        mo.observe(document.body, { childList: true, subtree: true });
        onDOMChanged();
    }

    console.log('Brand Logo v2 by PapaThemes');
    $ = await loadjQuery();
    $(document).ready(onReady);
})();